<template>
    <base-btn
        :loading="loading"
        class="btn-block bg-google"
        variant="link"
        @click.native="$emit('click')"
    >
        <base-icon name="google" />
        <span v-html="$t('views_user_signup_0019')" />
    </base-btn>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
