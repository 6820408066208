var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-btn",
    {
      staticClass: "btn-block bg-google",
      attrs: { loading: _vm.loading, variant: "link" },
      nativeOn: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("base-icon", { attrs: { name: "google" } }),
      _vm._v(" "),
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.$t("views_user_signup_0019")) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }